import { createSelector } from "reselect"
import get from "lodash/get"

const loadingState = state => state.loading

const loadingActions = {
  LOAD_ARTICLES: "LOAD_ARTICLES",
}

export const isLoadArticlesLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.LOAD_ARTICLES)
)
