import * as articleActionCreators from "../store/actions/actionCreators"
import * as articleSelectors from "../store/selectors"
import * as searchSelectors from "../../search/store/selectors"

import { ENTITY_TYPES, entityFilterlabel } from "../../common/utils"
import React, { useState } from "react"
import {
  useDisabledIntercomEffect,
  useSearchResultEffect,
} from "../../common/effects"

import ArticleList from "../components/ArticleList"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import Loading from "../../common/components/Loading"
import NoResults from "../../common/components/NoResults"
import SEO from "../../layout/components/SEO"
import SearchableItems from "../../search/components/SearchableItems"
import { connect } from "react-redux"
import { isLoadArticlesLoading$ } from "../store/selectors/loadingSelectors"
import { loadMore } from "../../common/utils/pagination"
import searchActionCreators from "../../search/store/actions/action-creators"

function ArticlesTemplate({
  filter,
  pageInfo,
  loading,
  canLoadMore,
  searchResults,
  searchResultsCount,
  addFilter,
  addArticlePageInfo,
  location,
}) {
  const [results, setResults] = useState(searchResults)

  useDisabledIntercomEffect()
  useSearchResultEffect(setResults, searchResults)

  const handleLoadMore = () =>
    loadMore(filter, pageInfo, ENTITY_TYPES.ARTICLES, addArticlePageInfo)

  return (
    <Layout>
      <SEO
        title="Articles"
        keywords="loyalty offers rewards articles whatsmine blog"
        url={location.href}
      />
      <SearchableItems
        title={entityFilterlabel(ENTITY_TYPES.ARTICLES, filter)}
        resultCount={searchResultsCount}
        canLoadMore={canLoadMore}
        loadMore={handleLoadMore}
        entityType={ENTITY_TYPES.ARTICLES}
        disableFilter={true}
      >
        <EntitiesContainer>
          {results.length ? (
            <ArticleList entities={results} />
          ) : loading ? (
            <Loading text="Loading articles..." />
          ) : (
            <NoResults />
          )}
        </EntitiesContainer>
      </SearchableItems>
    </Layout>
  )
}

const mapStateToProps = (state, props) => ({
  filter: searchSelectors.filter$(state),
  pageInfo: articleSelectors.filteredPageInfo$(state),
  loading: isLoadArticlesLoading$(state),
  canLoadMore: articleSelectors.canLoadMore$(state),
  searchResults: articleSelectors.paginatedSearchResults$(state),
  searchResultsCount: articleSelectors.searchResultsCount$(state),
})

const mapDispatchToProps = {
  addFilter: searchActionCreators.addFilter,
  addPagination: searchActionCreators.addPagination,
  addArticlePageInfo: articleActionCreators.addArticlePageInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesTemplate)
